<template>
  <div class="w100 bg-fff pl-24 pr-24 pt-30 box wtm_height2 autobox">
    <seanhead  @searh="searchClick"></seanhead>
    <div class="signbox w100 dp-f fw-w">
      <!-- <div class="morebox mr-14 dp-fc cu-p" @click="newSeal">
        <img class="mr-4 pt-2" src="@/assets/img/general/add.png">新增印章
      </div> -->
      <signItems :datas="state.Notice" :type="2" :height="310" @DetailEmit="todetail"></signItems>
    </div>
    <!-- <dialog22 ref="newsign" :options="state.options"></dialog22> -->
    <el-pagination class="dp-f jc-c mb-20" @current-change="handleCurrentChange" background
      layout="prev, pager, next,slot" :current-page="currentPage" :total="totalPage" :page-size=10>
    </el-pagination>
  </div>
</template>
<script setup>
import { onMounted, reactive, ref, unref } from 'vue'
import seanhead from "./seanhead.vue"
import { router_push_name } from "@/utils/server/router.js";
// import dialog22 from './dialog.vue'
// import dialog22 from '../../../sign/components/my/dialog.vue'
import signItems from "@/views/sign/components/signItems.vue"
import { httpToken } from '@/utils/request'
import qs from 'qs'
const loading = ref(false);//loading 显示
const currentPage = ref(1);//当前页数
const totalPage = ref(0);//总条数
const newsign = ref()
const state = reactive({
  // status 1 通过 2停用 3 待审核 4未通过 5 已使用
  Notice: [],
  options: [],
})
// 分页改变方法
const handleCurrentChange = ((e) => {
  currentPage.value = e
  getdatalb(currentPage.value, state.datasearchjson);
})
// const newSeal = () => {
//   unref(newsign).addseal();
//   unref(newsign).getTemplate()
// };
// 列表调取 页数 传入构造器
let getdatalb = ((val, searchjson = []) => {
  
  loading.value = true
  let json = [
    // { column: "seal.seal_type", type: 'ge', value: '5' }
  ];
  for (let i = 0; i < searchjson.length; i++) {
    json.push(searchjson[i]);
  }
  currentPage.value = val;
  state.datasearchjson = searchjson;
  httpToken({
    method: "post",
    url: '/seal/seal-user/getMySealList',
    data: qs.stringify({
      page: val,
      rows: 10,
      paramData: JSON.stringify(json)
    }),
  }).then((res) => {
    // 如果存在请求页码和返回数据时页面不同的情况 按返回页码重新请求
    if (val !== res.data.current) {
      getdatalb(res.data.current, searchjson)
      return
    }

    totalPage.value = res.data.total;
    // totalofpages.value = res.data.pages;
    state.Notice = res.data.records
    console.log(state.Notice,'这是一个业务章')
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  })
})
const searchClick=(e)=>{
  console.log(22222)
  state.datasearchjson=[
  { column: "seal_status", type: 'eq',value:e.status },
  { column: "seal_name", type: 'like',value:e.content}
  ]
  getdatalb(currentPage.value, state.datasearchjson);
}
const todetail = (e) => {
  router_push_name("mysign_sealParticulars", e);
};

onMounted(() => {
  getdatalb(currentPage.value, state.datasearchjson);

})

</script>
<style lang="scss" scoped >
@import "@/styles/color/value.scss";
.box {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.morebox {
  width: 250px;
  height: 310px;
  background: linear-gradient(180deg, rgba(238, 199, 116, 0) 0%, $active-alpha 100%);
  border-radius: 10px;
  border: 1px solid $active-alpha;
  font-weight: 500;
  color: #333333;
}

.signbox {
  min-height: 600px;
}</style>