<template>
    <div class="w100 dp-f fw-w mb-24">
        <div class="input_box w-200">
            <div class="input_title">状态：</div>
            <el-select v-model="state.searchKey.status" class="input" size="large">
                <el-option
                v-for="item in state.statusoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                />
            </el-select>
        </div>
        <div class="input_box w-180">
            <el-input class="input search_input" placeholder="请输入搜索关键字" v-model="state.searchKey.content"
                :maxlength="config.inputMaxlength" show-word-limit>
                <template #append>
                     <img src="@/assets/img/general/search.png" class="w-16 h-16 cu-p" @click="emit('searh',state.searchKey)" >
                </template>
            </el-input>
        </div>
        <oabutton width='80' height='40' title="搜索" CSStype=2 style="border-radius:4px;" class="ml-12"
        @buttonclick="emit('searh',state.searchKey)"></oabutton>
    </div>
</template>
<script setup>
import { reactive, ref, unref,defineEmits } from 'vue'
import {sealType} from '@/utils/server/selectdata'


const state = reactive({
//   options: sealType(1),//下拉数据
  statusoptions:[{label:'全部',value:null},{label:'使用中',value:'1'},{label:'停用',value:'2'}],

  searchKey: {
    status: null,//状态
    content:''
  },//搜索绑定值
})
const emit=defineEmits(['searh'])



</script>